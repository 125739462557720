@charset "utf-8";
@import './_breakpoint';
@import './_mixin';
@import './color';

* {
  overscroll-behavior-x: none !important;
}

.btn {
  box-shadow: none !important;
}

.table {
  --bs-table-striped-bg: transparent;
  --bs-table-active-bg: transparent;
  --bs-table-hover-bg: transparent;
}

select {
  box-shadow: none !important;
}

input {
  box-shadow: none !important;
}

h3,
h4,
p,
.form-label,
.form-check,
.modal-body {
  color: $color_gray;
}

body,
#root {
  height: 100vh;
}

// Header
.color-nav {
  padding: 0;
  margin: 0;
  background-color: $color_primary;

  .color-nav-title {
    color: $color_white;
  }

  // Humburger Menu
  .navbar-toggler {
    margin-right: 20px;
    border: 1px solid $color_white;

    &:focus {
      box-shadow: none;
    }

    .navbar-toggler-icon {
      filter: invert(100%) sepia(36%) saturate(301%) hue-rotate(235deg)
        brightness(118%) contrast(100%);
    }
  }

  @include mq(max-lg-bootstrap) {
    .nav-disappear {
      display: none;
    }

    .color-nav-title {
      display: none;
    }
  }

  @include mq(min-lg-bootstrap) {
    .color-nav-title {
      display: none;
    }

    a {
      margin-left: 30px;

      .nav-content {
        font-size: 18px;
      }
    }

    .btn {
      font-size: 18px;
    }
  }

  @include mq(xl) {
    .color-nav-title {
      display: inline;
    }

    a {
      margin-left: 40px;

      .nav-content {
        font-size: 20px;
      }
    }

    .btn {
      font-size: 20px;
    }
  }

  .navbar-brand {
    margin: 10px 20px 0;
  }

  a {
    padding-bottom: 20px;
    color: $color_white;
    text-align: center;
    text-decoration: none;

    .nav-content {
      margin: 0;
      margin-top: 20px;
      color: $color_white;
    }
  }

  .btn {
    padding: 7px 25px;
    margin-right: 30px;
    color: $color_white;
    background: none;
  }
}

// Humburger Menu Modal styles
.navbar-expand-lg {
  justify-content: space-between;
}

.offcanvas {
  .offcanvas-header {
    .offcanvas-title {
      padding-left: 10px;
      color: $color_primary;
    }
  }

  .offcanvas-body {
    .navbar-nav {
      .active::before {
        display: none;
      }

      a {
        text-decoration: none;

        h3 {
          font-size: 20px;
          color: $color_primary;

          &:hover {
            text-decoration: underline $color_primary;
          }
        }
      }
    }

    .btn {
      margin-top: 20px;
      font-size: 20px;
      color: $color_primary;
      background-color: $color_white;
      border: 3px solid $color_primary;

      &:hover {
        color: $color_secondary;
        border: 3px solid $color_secondary;
      }
    }
  }
}

// Play menu buttons bar
.play-menu {
  border-bottom: solid #707070 0;

  .icon-color {
    color: $color_gray;

    &:hover {
      color: $color_secondary;
    }
  }

  .icon-color_off {
    color: #e5e5e5;

    &:hover {
      color: $color_secondary;
    }
  }

  // 画像選択モードの画像選択キャンセルボタンの色
  .icon-color_danger {
    color: $color_gray;

    &:hover {
      color: $color_red;
    }
  }

  .select-style {
    min-width: 60px;
    margin: auto 0;

    &:hover {
      border: 2px solid $color_secondary;
    }
  }

  .btn-group {
    .btn-primary {
      height: 35px;
      font-weight: bold;
      color: $color_gray;
      background-color: $color_white;
      border: 1px solid $color_gray;

      &:hover {
        color: $color_secondary;
        border: 1px solid $color_secondary;
      }
    }

    .btn-check:checked + .btn-primary {
      color: $color_white;
      background-color: $color_gray;
      border: 1px solid $color_gray;

      &:hover {
        background-color: $color_secondary;
        border: 1px solid $color_secondary;
      }
    }
  }
}

// 画像選択モードの画像選択状況アイコンの色
.file-checked {
  color: $color_green;
}

.file-unchecked {
  color: $color_gray;
}

.params-input-group {
  margin: 5px 0;

  .col {
    margin: auto 0;

    p {
      margin: auto 0;
    }
  }
}

// Setting Modal
.setting_modal {
  .setting_modal-content_wrap {
    padding: 10px 30px 40px;
    color: $color_gray;

    .content_wrap-table {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Help Modal
.help-modal-table {
  color: $color_gray;

  tr:nth-child(odd) > th {
    font-weight: 500;
    color: $color_gray;
    background-color: #f2f5fc;
  }

  tr:nth-child(odd) > td {
    color: $color_gray;
    background-color: #f2f5fc;
  }

  th {
    min-width: 100px;
    max-width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
  }

  td {
    font-size: 18px;
    color: $color_gray;
    text-align: left;
    vertical-align: middle;

    h4 {
      margin: 0;
      text-align: left;
      vertical-align: middle;
    }
  }
}

// Report Page
.report-wrap {
  padding: 2%;

  h4 {
    margin: 0;
  }

  .col {
    width: 200px;
  }

  .col-md-8 {
    padding: 0;
  }

  .report-wrap_form {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .form-control {
      width: 100%;
      text-overflow: 'ellipsis';
    }
  }

  .report-btn_wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 0;

    .btn_wrap-content {
      display: flex;
      align-items: center;

      span {
        margin-top: 10px;
        color: $color_gray;
      }

      :not(:last-child) {
        margin-right: 10px;
      }
    }

    .btn-style {
      margin-top: 10px;
    }
  }
}

.error-message {
  color: red;
}

canvas {
  border: 0;
  image-rendering: crisp-edges; /* Firefox用 */
  image-rendering: pixelated; /* Chrome用 */
}

.l-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.l-ul {
  // position: fixed;
  top: 0;
  z-index: 20;
  height: 10%;
  margin: 0 auto;
  overflow: hidden;
  list-style: none;

  .l-li {
    display: inline-block;
    padding: 26px 10px 20px;
  }
}

.coias-ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  list-style: none;

  .coias-li {
    padding: 0;
  }
}

.coias-button {
  width: auto;
  padding: 5px;
}

.cpias-input {
  width: 90%;
  height: 50%;
}

.coias-img {
  width: 90%;
  height: 90%;
}

.active {
  position: relative;

  // padding-bottom: 20px;
}

.active:hover {
  color: $color_white;
  cursor: pointer;
}

.active::before {
  position: absolute;
  bottom: -8px;
  left: 50%;
  display: block;
  width: 70%;
  height: 4px;
  content: '';
  background-color: $color_white;
  transform: translate(-50%);
}

.not-active:hover {
  color: $color_white;
  cursor: pointer;
}

.not-active-no-hover:hover {
  color: $color_gray;
  cursor: default;
}

footer {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  text-align: center;
}

.wrapper {
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    padding: 0;
    margin: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color_gray;

    --bg-opacity: 1;
  }
}

.coias-view-main {
  width: 100%;
  height: calc(100% - 63px);
  margin: 0;

  .col {
    padding: 0;
  }

  .row {
    height: 100%;
    padding: 0;

    .col {
      height: 100%;
    }

    .container-fluid {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;

      .row {
        height: 100%;

        .manual-mode-control-view {
          height: 100%;

          .main-canvas {
            height: 100%;
          }

          .wrapper {
            height: 100%;
          }
        }
      }
    }

    .slidebar-time-wrapper {
      display: flex;
      flex-wrap: wrap;

      .slidebar-wrapper {
        position: absolute;
        z-index: 999;
        display: flex;
        align-items: center;
        width: 360px;
        height: 35px;
        padding: 0;
        padding: 6px;
        margin: auto 0;
        background-color: black;
        opacity: 0.5;

        @include mq(min-main-canvas) {
          top: 35px;
          right: 15px;
        }

        .bright-contrast-bar {
          display: flex;
          width: 100%;
          height: 100%;
          margin: auto 0;
          text-align: center;
        }
      }
    }

    // 画面右側　Style "manual"
    .manual-toolbar_wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .manual-star-list-wrraper {
      // position: relative;
      height: calc(100% + 63px);
      border-left: solid #707070 1px;

      @include mq(md) {
        .manual-btn-fixed {
          // position: absolute;
          // bottom: 5%;
          // left: 40%;
          display: flex;
          justify-content: right;
          width: 100%;
          padding: 10px 10px 10px 0;
          margin-top: auto;
        }
      }

      @include mq(lg) {
        .manual-btn-fixed {
          // position: absolute;
          // bottom: 5%;
          // left: 50%;
          display: flex;
          justify-content: right;
          width: 100%;
          padding: 10px 10px 10px 0;
          margin-top: auto;
        }
      }

      @include mq(xl) {
        .manual-btn-fixed {
          // position: absolute;
          // bottom: 5%;
          // left: 60%;
          display: flex;
          justify-content: right;
          width: 100%;
          padding: 10px 10px 10px 0;
          margin-top: auto;
        }
      }

      .manual-btn-fixed-delete {
        // position: absolute;
        // bottom: 5%;
        // left: 1%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 10px 10px 0;
        margin-top: auto;
      }
    }

    // 画面右側　Style "coias"
    .coias-star-list-wrraper {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: calc(100% + 63px);
      border-left: solid #707070 1px;

      // width: 13vw;
      .coias-list_button {
        display: flex;
        justify-content: end;
        width: 100%;
        padding: 10px 10px 10px 0;
        margin-top: auto;
      }
    }

    // 天体一覧部分　Style
    .star-list-title {
      width: 100%;
    }

    .star-list {
      width: 100%;
      height: 80%;
      padding: 0;
      margin: 0;
      overflow-y: scroll;
      color: $color_gray;

      .form-check-label {
        &:hover {
          color: $color_secondary;
          cursor: pointer;
        }
      }

      // 星のリストのスクロールバーを常に表示する
      &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #707070;

        --bg-opacity: 1;
      }
    }
  }
}

.manual-list {
  max-height: 65vh;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color_gray;

    --bg-opacity: 1;
  }
}

.blink-button {
  margin-left: 10px;
}

.search_suggestions {
  color: $color_gray;

  .active,
  div:hover {
    background-color: $color_secondary;
  }
}

.params-btn {
  margin-left: 28px;
}

// Check Button and Accordion Button Styles
.form-check-input {
  cursor: pointer;

  &:checked {
    background-color: $color_gray;
    border: $color_gray;

    &:hover {
      background-color: $color_secondary;
      border: $color_secondary;
    }

    &:focus {
      border: $color_gray;
    }
  }

  &:hover,
  &:focus {
    border: 1px solid $color_secondary;
  }
}

.accordion-button {
  color: $color_gray;

  &:focus {
    box-shadow: none;
  }

  &::after {
    filter: invert(39%) sepia(46%) saturate(19%) hue-rotate(337deg)
      brightness(101%) contrast(92%);
  }

  &:not(.collapsed) {
    color: $color_gray;
    background-color: #5c636a33;
  }
}

.form-control {
  color: $color_gray;
  border: 2px solid #e5e5e5;

  &:focus {
    border: 2px solid $color_secondary;
  }
}

// Button Style
.btn-style {
  &.box_blue {
    background-color: $color_primary;
    border: 3px solid $color_primary;

    &:hover {
      background-color: $color_secondary;
      border: 3px solid $color_secondary;
    }
  }

  &.box_border_blue {
    color: $color_primary;
    background-color: $color_white;
    border: 3px solid $color_primary;

    &:hover {
      color: $color_secondary;
      background-color: $color_white;
      border: 3px solid $color_secondary;
    }
  }

  &.box_border_gray {
    color: $color_gray;
    background-color: $color_white;
    border: 3px solid $color_gray;

    &:hover {
      color: $color_secondary;
      background-color: $color_white;
      border: 3px solid $color_secondary;
    }
  }

  &.box_gray {
    color: $color_white;
    background-color: #6d757d;
    border: 3px solid #6d757d;

    &:hover {
      background-color: $color_gray;
      border: 3px solid $color_gray;
    }
  }
}

.btn-close {
  &:hover {
    filter: invert(65%) sepia(67%) saturate(273%) hue-rotate(190deg)
      brightness(93%) contrast(89%);
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-style_hover {
  .btn {
    &:hover {
      opacity: 0.8;
    }
  }
}

.dropdown_style {
  padding: 0 13px;
  color: white;
  border-radius: 4px;
}

.dropdown_style_prepare {
  padding: 6px 13px;
  color: white;
  border-radius: 4px;
}

.dropdown-item {
  &:active {
    background-color: $color_secondary;
  }
}

.selected-files-table {
  thead {
    color: $color_white;
    background-color: #6c757d;
  }

  tbody {
    background-color: #f2f5fc;

    tr:nth-child(odd) > td {
      background-color: #fff;
    }
  }
}

.selected-image-files-table {
  color: $color_white;

  thead {
    color: $color_white;
    background-color: $color_table_background;
  }

  tbody {
    color: $color_white;
    background-color: $color_secondary;

    tr:nth-child(odd) > td {
      color: $color_white;
      background-color: $color_primary;
    }
  }
}

.agree-modal-table {
  tbody {
    font-weight: bold;
    color: $color_white;
    background-color: $color_secondary;
  }

  tr:nth-child(odd) > td {
    font-weight: bold;
    color: $color_white;
  }
}

.celebrate-image {
  position: relative;
  color: white;
  text-align: center;

  @include mq(sm) {
    .celebrate-image-text {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);

      h3 {
        font-size: medium;
      }

      h4 {
        font-size: small;
      }

      aside {
        font-size: smaller;
      }
    }
  }

  @include mq(md) {
    .celebrate-image-text {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);

      h3 {
        font-size: large;
      }

      h4 {
        font-size: medium;
      }

      aside {
        font-size: small;
      }
    }
  }

  @include mq(xl) {
    .celebrate-image-text {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);

      h3 {
        font-size: x-large;
      }

      h4 {
        font-size: larger;
      }

      aside {
        font-size: large;
      }
    }
  }
}

.n-measure-object {
  font-size: 26px;
  font-weight: bold;
  color: $color_primary;
}
