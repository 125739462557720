// =======================
// Color
// =======================

$color_primary: #6b80b6;
$color_secondary: #94a9e0;
$color_gray: #5c636a;
$color_table_background: #6c757d;
$color_white: #fff;
$color_green: #5aff19;
$color_red: #f00;
